.custom-calendar-component {
    .rbc-toolbar {}

    .rbc-month-view {
        border: 1px solid #EEEFF2;

        .rbc-month-header {
            background-color: #EAEDF2;
            height: 45px;

            .rbc-header {
                height: 100%;
                display: flex;
                align-items: center;
                border: 1px solid #EEEFF2;

                span {
                    font-size: 14px;
                    font-family: $font-family-semibold;
                    color: $color-secondary;
                    padding-left: 25px;
                }
            }
        }

        .rbc-month-row {
            .rbc-off-range-bg {
                background: white;
                color: #E0E0E0;
            }

            .rbc-today {
                // background-color: rgba($color-active, 0.2);
                background-color: white;
            }
        }

        .rbc-event {
            background-color: transparent !important;
            position: relative;
    
            .rbc-event-content {
                font-size: 14px;
                font-family: $font-family-semibold;
                color: $color-second;
                display: flex;
    
                .primary {
                    font-size: 12px;
                    font-family: $font-family-semibold;
                    color: $color-primary;
                    background-color: rgba($color-primary, 0.10) !important;
                    height: 24px;
                    align-items: center;
                    display: flex;
                    padding-right: 15px;
                    padding-left: 15px;
                    border-radius: 13px;
                    border: 1px solid transparent;
                }
    
                .second {
                    font-size: 12px;
                    font-family: $font-family-semibold;
                    color: $color-second;
                    background-color: rgba($color-second, 0.10) !important;
                    height: 24px;
                    align-items: center;
                    display: flex;
                    padding-right: 15px;
                    padding-left: 15px;
                    border-radius: 13px;
                    border: 1px solid transparent;
                }
            }
            
            &:focus {
                outline: 0px;
    
                .rbc-event-content {
                    .primary {
                        border: 1px solid $color-primary;
                    }
        
                    .second {
                        border: 1px solid $color-second;
                    }
                }
            }
        }
    }


    .rbc-time-view {
        .rbc-time-header {
            background-color: #EAEDF2;
            height: 45px;
            margin-right: 0px !important;

            .rbc-today {
                background-color: #EAEDF2;
            }
        }

        .rbc-time-content {
            .rbc-today {
                background-color: white;
            }
        }

        .rbc-events-container {
            .rbc-event {
                background-color: transparent !important;
                border: 1px solid transparent;
        
                .rbc-event-content {
                    border: 1px solid transparent;
                    font-size: 14px;
                    font-family: $font-family-semibold;
                    color: $color-second;
        
                    .primary {
                        font-size: 12px;
                        font-family: $font-family-semibold;
                        color: $color-primary;
                        background-color: rgba($color-primary, 0.10) !important;
                        align-items: center;
                        display: flex;
                        padding-right: 15px;
                        padding-left: 15px;
                        border-radius: 13px;
                        border: 1px solid transparent;
                    }
        
                    .second {
                        font-size: 12px;
                        font-family: $font-family-semibold;
                        color: $color-second;
                        background-color: rgba($color-second, 0.10) !important;
                        align-items: center;
                        display: flex;
                        padding-right: 15px;
                        padding-left: 15px;
                        border-radius: 13px;
                        border: 1px solid transparent;
                    }
                }
                
                &:focus, &.rbc-selected {
                    outline: 0px;
        
                    .rbc-event-content {
                        .primary {
                            border: 1px solid $color-primary;
                        }
            
                        .second {
                            border: 1px solid $color-second;
                        }
                    }
                }
            }
        }
    }

    .rbc-current-time-indicator {
        background-color: $color-second;
    }
}