.custom-tabs-component {
    position: relative;

    .custom-tabs {
        min-height: 30px;

        > div {
            > div {
                @include respond-below('md') {
                    display: block !important;
                }
            }
        }

        button {
            // background-color: $bg-color;
            padding: 0px;
            border-bottom: 1px solid $bg-color;
            
            span {
                cursor: pointer;
                font-weight: 500;
                // color: $color-secondary;
            }

            label {
                cursor: pointer;
            }

            &[aria-selected="true"] {
                // background-color: $color-active;

                span {
                    color: $white;
                }
            }

            @include respond-below('md') {
                display: block !important;
                width: 100%;
                min-width: 100%;
            }
        }

        .MuiTabs-indicator {
            background-color: $color-second !important;
        }

        @include respond-below('md') {
            display: block !important;
            width: 100%;
            min-width: 100%;
        }
    }

    .custom-panel {
        
    }
}