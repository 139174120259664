.custom-button-component {
    button {
        border-radius: 4px;
        background-color: $color-default;
        height: 43px;
        min-width: 43px;
        box-shadow: unset;
        padding: 6px 12px;
        text-transform: unset;

        .MuiButton-label {
            text-transform: none;
            color: $color-secondary;
            font-size: 14px;
            font-family: $font-family-regular;

            span, div, i, svg {
                text-transform: none;
                color: $color-secondary;
                font-size: 14px;
                font-family: $font-family-regular;
            }
            svg {
                fill: rgba($color-secondary, 0.7);
    
                rect, g, path {
                    fill: rgba($color-secondary, 0.7);
                }
            }

            &:hover {
                color: rgba($color-secondary, 0.8);

                span, div, i, svg {
                    color: rgba($color-secondary, 0.8);
                }
            }
        }

        &:hover {
            box-shadow: unset;
        }

        &.MuiButton-containedPrimary, &.MuiButton-textPrimary {
            background-color: $color-active;
            
            &:hover {
                background-color: rgba($color-active, 0.8);
            }

            .MuiButton-label {
                color: white;

                span, div, i, svg {
                    color: white;
                }

                svg {
                    fill: white;
        
                    rect, g, path {
                        fill: white;
                    }
                }
            }
        }
        &.MuiButton-containedSecondary, &.MuiButton-textSecondary {
            background-color: $color-second;

            &:hover {
                background-color: rgba($color-second, 0.8);
            }

            .MuiButton-label {
                color: white;

                span, div, i, svg {
                    color: white;
                }

                svg {
                    fill: white;
        
                    rect, g, path {
                        fill: white;
                    }
                }
            }
        }

        &.MuiButton-outlinedPrimary {
            background-color: unset;
            border: 1px solid $color-active;
            
            .MuiButton-label {
                color: $color-active;

                span, div, i, svg {
                    color: $color-active;
                }

                svg {
                    fill: $color-active;
        
                    rect, g, path {
                        fill: $color-active;
                    }
                }
            }

            &:hover {
                background-color: rgba($color-active, 0.8);

                .MuiButton-label {
                    color: white;
    
                    span, div, i, svg {
                        color: white;
                    }
    
                    svg {
                        fill: white;
            
                        rect, g, path {
                            fill: white;
                        }
                    }
                }
            }
        }
        &.MuiButton-outlinedSecondary {
            background-color: unset;
            border: 1px solid $color-second;

            .MuiButton-label {
                color: $color-second;

                span, div, i, svg {
                    color: $color-second;
                }

                svg {
                    fill: $color-second;
        
                    rect, g, path {
                        fill: $color-second;
                    }
                }
            }

            &:hover {
                background-color: rgba($color-second, 0.8);

                .MuiButton-label {
                    color: white;
    
                    span, div, i, svg {
                        color: white;
                    }
    
                    svg {
                        fill: white;
            
                        rect, g, path {
                            fill: white;
                        }
                    }
                }
            }
        }

        &.MuiButton-sizeSmall {
            height: 35px;
        }
        &.MuiButton-sizeLarge {
            height: 50px;
        }
    }


    a {
        border-radius: 4px;
        background-color: unset;
        height: auto;
        box-shadow: unset;
        text-transform: unset;

        &.MuiButton-text {
            color: unset;

            .MuiButton-label {
                color: $color-secondary;
                text-decoration: none;

                span, div, i, svg {
                    color: $color-secondary;
                }

                svg {
                    fill: $color-secondary;
        
                    rect, g, path {
                        fill: $color-secondary;
                    }
                }
            }
            
            &:hover {
                background-color: unset;

                .MuiButton-label {
                    color: rgba($color-secondary, 0.5);
                    background-color: unset;

                    span, div, i, svg {
                        color: rgba($color-secondary, 0.5);
                    }

                    svg {
                        fill: rgba($color-secondary, 0.5);
            
                        rect, g, path {
                            fill: rgba($color-secondary, 0.5);
                        }
                    }
                }
            }
        }

        &.MuiButton-textPrimary, &.MuiButton-containedPrimary {
            color: unset;
            box-shadow: unset;

            .MuiButton-label {
                color: $color-active;
                text-decoration: none;

                span, div, i, svg {
                    color: $color-active;
                }

                svg {
                    fill: $color-active;
        
                    rect, g, path {
                        fill: $color-active;
                    }
                }
            }
            
            &:hover {
                background-color: unset;

                .MuiButton-label {
                    color: rgba($color-active, 0.5);
                    background-color: unset;

                    span, div, i, svg {
                        color: rgba($color-active, 0.5);
                    }

                    svg {
                        fill: rgba($color-active, 0.5);
            
                        rect, g, path {
                            fill: rgba($color-active, 0.5);
                        }
                    }
                }
            }
        }

        &.MuiButton-textSecondary, &.MuiButton-containedSecondary {
            color: unset;
            box-shadow: unset;

            .MuiButton-label {
                color: $color-second;
                text-decoration: none;

                span, div, i, svg {
                    color: $color-second;
                }

                svg {
                    fill: $color-second;
        
                    rect, g, path {
                        fill: $color-second;
                    }
                }
            }
            
            &:hover {
                background-color: unset;

                .MuiButton-label {
                    color: rgba($color-second, 0.5);
                    background-color: unset;

                    span, div, i, svg {
                        color: rgba($color-second, 0.5);
                    }

                    svg {
                        fill: rgba($color-second, 0.5);
            
                        rect, g, path {
                            fill: rgba($color-second, 0.5);
                        }
                    }
                }
            }
        }
    }


    &.upload-button {
        input {
            display: none !important;
        }

        .upload-label {
            > span {
                border-radius: 4px;
                background-color: $color-default;
                height: 43px;
                min-width: 43px;
                box-shadow: unset;
                padding: 6px 12px;
                text-transform: unset;

                .MuiButton-label {
                    text-transform: none;
                    color: $color-secondary;
                    font-size: 14px;
                    font-family: $font-family-regular;

                    span, div, i, svg {
                        text-transform: none;
                        color: $color-secondary;
                        font-size: 14px;
                        font-family: $font-family-regular;
                    }
                    svg {
                        fill: rgba($color-secondary, 0.7);
            
                        rect, g, path {
                            fill: rgba($color-secondary, 0.7);
                        }
                    }

                    &:hover {
                        color: rgba($color-secondary, 0.8);

                        span, div, i, svg {
                            color: rgba($color-secondary, 0.8);
                        }
                    }
                }

                &:hover {
                    box-shadow: unset;
                }

                &.MuiButton-containedPrimary, &.MuiButton-textPrimary {
                    background-color: $color-active;
                    
                    &:hover {
                        background-color: rgba($color-active, 0.8);
                    }

                    .MuiButton-label {
                        color: white;

                        span, div, i, svg {
                            color: white;
                        }

                        svg {
                            fill: white;
                
                            rect, g, path {
                                fill: white;
                            }
                        }
                    }
                }
                &.MuiButton-containedSecondary, &.MuiButton-textSecondary {
                    background-color: $color-second;

                    &:hover {
                        background-color: rgba($color-second, 0.8);
                    }

                    .MuiButton-label {
                        color: white;

                        span, div, i, svg {
                            color: white;
                        }

                        svg {
                            fill: white;
                
                            rect, g, path {
                                fill: white;
                            }
                        }
                    }
                }

                &.MuiButton-outlinedPrimary {
                    background-color: unset;
                    border: 1px solid $color-active;
                    
                    .MuiButton-label {
                        color: $color-active;

                        span, div, i, svg {
                            color: $color-active;
                        }

                        svg {
                            fill: $color-active;
                
                            rect, g, path {
                                fill: $color-active;
                            }
                        }
                    }

                    &:hover {
                        background-color: rgba($color-active, 0.8);

                        .MuiButton-label {
                            color: white;
            
                            span, div, i, svg {
                                color: white;
                            }
            
                            svg {
                                fill: white;
                    
                                rect, g, path {
                                    fill: white;
                                }
                            }
                        }
                    }
                }
                &.MuiButton-outlinedSecondary {
                    background-color: unset;
                    border: 1px solid $color-second;

                    .MuiButton-label {
                        color: $color-second;

                        span, div, i, svg {
                            color: $color-second;
                        }

                        svg {
                            fill: $color-second;
                
                            rect, g, path {
                                fill: $color-second;
                            }
                        }
                    }

                    &:hover {
                        background-color: rgba($color-second, 0.8);

                        .MuiButton-label {
                            color: white;
            
                            span, div, i, svg {
                                color: white;
                            }
            
                            svg {
                                fill: white;
                    
                                rect, g, path {
                                    fill: white;
                                }
                            }
                        }
                    }
                }

                &.MuiButton-sizeSmall {
                    height: 35px;
                }
                &.MuiButton-sizeLarge {
                    height: 50px;
                }
            }
        }
    }



    &.underline {
        .MuiButton-label {
            text-decoration: underline !important;
        }
        .MuiButton-root {
            padding: 0px;
        }
    }

    .Mui-disabled {
        background-color: rgba($color-secondary, 0.3) !important;

        .MuiButton-label {
            color: $color-secondary !important;

            span, div, i, svg {
                color: $color-secondary !important;
            }
            svg {
                fill: rgba($color-secondary, 0.7) !important;
    
                rect, g, path {
                    fill: rgba($color-secondary, 0.7) !important;
                }
            }

            &:hover {
                color: rgba($color-secondary, 0.8) !important;

                span, div, i, svg {
                    color: rgba($color-secondary, 0.8) !important;
                }
            }
        }
    }


    &.outlined-default-primary {
        button {
            border-radius: 4px;
            background-color: unset;
            height: 43px;
            min-width: 43px;
            box-shadow: unset;
            padding: 6px 12px;
            border: 1px solid #D7DAE2;
    
            .MuiButton-label {
                text-transform: none;
                color: #D7DAE2;
                font-size: 14px;
                font-family: $font-family-regular;
    
                span, div, i, svg {
                    text-transform: none;
                    color: #D7DAE2;
                    font-size: 14px;
                    font-family: $font-family-regular;
                }
                svg {
                    fill: rgba(#D7DAE2, 0.7);
        
                    rect, g, path {
                        fill: rgba(#D7DAE2, 0.7);
                    }
                }
    
                &:hover {
                    color: $color-active;
    
                    .MuiButton-label {
                        color: $color-active;
        
                        span, div, i, svg {
                            color: $color-active;
                        }
        
                        svg {
                            fill: $color-active;
                
                            rect, g, path {
                                fill: $color-active;
                            }
                        }
                    }
                }
            }
    
            &:hover {
                background-color: unset;
                box-shadow: unset;
                border: 1px solid $color-active;

                &:hover {
                    color: $color-active;
    
                    .MuiButton-label {
                        color: $color-active;
        
                        span, div, i, svg {
                            color: $color-active;
                        }
        
                        svg {
                            fill: $color-active;
                
                            rect, g, path {
                                fill: $color-active;
                            }
                        }
                    }
                }
            }
    
            &.MuiButton-sizeSmall {
                height: 35px;
            }
            &.MuiButton-sizeLarge {
                height: 50px;
            }
        }

        &.active {
            button {
                background-color: unset;
                box-shadow: unset;
                border: 1px solid $color-active;

                .MuiButton-label {
                    color: $color-active;
    
                    span, div, i, svg {
                        color: $color-active;
                    }
    
                    svg {
                        fill: $color-active;
            
                        rect, g, path {
                            fill: $color-active;
                        }
                    }
                }
            }
        }
    }

    &.outlined-default-secondary {
        button {
            border-radius: 4px;
            background-color: unset;
            height: 43px;
            min-width: 43px;
            box-shadow: unset;
            padding: 6px 12px;
            border: 1px solid #D7DAE2;
    
            .MuiButton-label {
                text-transform: none;
                color: #D7DAE2;
                font-size: 14px;
                font-family: $font-family-regular;
    
                span, div, i, svg {
                    text-transform: none;
                    color: #D7DAE2;
                    font-size: 14px;
                    font-family: $font-family-regular;
                }
                svg {
                    fill: rgba(#D7DAE2, 0.7);
        
                    rect, g, path {
                        fill: rgba(#D7DAE2, 0.7);
                    }
                }
    
                &:hover {
                    color: $color-second;
    
                    .MuiButton-label {
                        color: $color-second;
        
                        span, div, i, svg {
                            color: $color-second;
                        }
        
                        svg {
                            fill: $color-second;
                
                            rect, g, path {
                                fill: $color-second;
                            }
                        }
                    }
                }
            }
    
            &:hover {
                background-color: unset;
                box-shadow: unset;
                border: 1px solid $color-second;

                &:hover {
                    color: $color-second;
    
                    .MuiButton-label {
                        color: $color-second;
        
                        span, div, i, svg {
                            color: $color-second;
                        }
        
                        svg {
                            fill: $color-second;
                
                            rect, g, path {
                                fill: $color-second;
                            }
                        }
                    }
                }
            }
    
            &.MuiButton-sizeSmall {
                height: 35px;
            }
            &.MuiButton-sizeLarge {
                height: 50px;
            }
        }

        &.active {
            button {
                background-color: unset;
                box-shadow: unset;
                border: 1px solid $color-second;

                .MuiButton-label {
                    color: $color-second;
    
                    span, div, i, svg {
                        color: $color-second;
                    }
    
                    svg {
                        fill: $color-second;
            
                        rect, g, path {
                            fill: $color-second;
                        }
                    }
                }
            }
        }
    }

    &.plr-0 {
        a {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
    
    &.pt-3 {
        a {
            padding-top: 3px;
        }
    }

    &.no-padding {
        button, a {
            padding: 0px;
        }
    }
}


.company-department-remove-btn {
    background-color: #F5F6F9 !important;
    padding: 5px !important;

    .MuiIconButton-label {
        i {
            font-size: 20px;
            color: #7D7E80;
        }
    }

    &:hover {
        background-color: #E94560 !important;
        
        .MuiIconButton-label {
            i {
                color: white;
            }
        }
    }
}

.company-department-edit-btn {
    background-color: #F5F6F9 !important;
    padding: 5px !important;

    .MuiIconButton-label {
        i {
            font-size: 20px;
            color: #7D7E80;
        }
    }

    &:hover {
        background-color: $color-active !important;
        
        .MuiIconButton-label {
            i {
                color: white;
            }
        }
    }
}


.primary-link {
    color: $color-active;

    &:hover {
        color: rgba($color-active, 0.5);
    }
}
.secondary-link {
    color: $color-active;

    &:hover {
        color: rgba($color-active, 0.5);
    }
}

.status-btn {
    width: 75%;
}