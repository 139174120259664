.custom-status-item-component {
    .custom-text {
        text-align: right;
        text-transform: uppercase;
        font-size: 12px;
        font-family: $font-family-semibold;
        color: $color-secondary;

        span {
            text-transform: uppercase;
            font-size: 12px;
            font-family: $font-family-semibold;
            color: $color-secondary;
        }

        .custom-dot {
            position: relative;
            top: 1px;
            font-size: 10px;
            color: $color-secondary;
            margin-left: 5px;
            margin-right: 5px;
        }
    }

    .custom-count {
        text-align: right;
        font-size: 20px;
        font-family: $font-family-bold;
        color: $color-secondary;

        span {
            font-size: 20px;
            font-family: $font-family-bold;
            color: $color-secondary;
        }
    }

    .custom-count-pl {
        padding-left: 10px;
    }
    
    .MuiBadge-badge {
        top: 9px;
        left: -10px;
    }
}


$color-not-assigned: #AE0264;
$color-assigned: #9BDEAC;
$color-acknowledged: #9D65C9;
$color-in-progress: #51ADCF;
$color-completed: #51ADCF;
$color-self-collect: #2EC1AC;
$color-cancelled: #7D5A5A;
$color-failed: #EA5455;

.status {
    width: 110px;
    &.not-assigned {
        color: $color-not-assigned !important;
    }
    &.assigned {
        color: $color-assigned !important;
    }
    &.acknowledged {
        color: $color-acknowledged !important;
    }
    &.in-progress {
        color: $color-in-progress !important;
    }
    &.completed {
        color: $color-completed !important;
    }
    &.self-collect {
        color: $color-self-collect !important;
    }
    &.cancelled {
        color: $color-cancelled !important;
    }
    &.failed {
        color: $color-failed !important;
    }
}

.status-label {
    border-radius: 6px !important;
    height: 26px !important;
    border: 0px !important;

    $opacity: 0.1;

    &.w-100 {
        width: 100%;
    }
    
    &.active {
        background-color: rgba($color-primary, $opacity) !important;

        span {
            color: $color-primary;
        }
    }
    &.inactive {
        background-color: rgba($color-second, $opacity) !important;

        span {
            color: $color-second;
        }
    }

    &.not-assigned {
        background-color: rgba($color-not-assigned, $opacity) !important;

        span {
            color: $color-not-assigned;
        }
    }
    &.assigned {
        background-color: rgba($color-assigned, $opacity) !important;

        span {
            color: $color-assigned;
        }
    }
    &.acknowledged {
        background-color: rgba($color-acknowledged, $opacity) !important;

        span {
            color: $color-acknowledged;
        }
    }
    &.in-progress {
        background-color: rgba($color-in-progress, $opacity) !important;

        span {
            color: $color-in-progress;
        }
    }
    &.completed {
        background-color: rgba($color-completed, $opacity) !important;

        span {
            color: $color-completed;
        }
    }
    &.self-collect {
        background-color: rgba($color-self-collect, $opacity) !important;

        span {
            color: $color-self-collect;
        }
    }
    &.cancelled {
        background-color: rgba($color-cancelled, $opacity) !important;

        span {
            color: $color-cancelled;
        }
    }
    &.failed {
        background-color: rgba($color-failed, $opacity) !important;

        span {
            color: $color-failed;
        }
    }
}

.status-btn {
    width: 110px;
    &.not-assigned {
        button {
            background-color: $color-not-assigned !important;
        }
    }
    &.assigned {
        button {
            background-color: $color-assigned !important;
        }
    }
    &.acknowledged {
        button {
            background-color: $color-acknowledged !important;
        }
    }
    &.in-progress {
        button {
            background-color: $color-in-progress !important;
        }
    }
    &.completed {
        button {
            background-color: $color-completed !important;
        }
    }
    &.self-collect {
        button {
            background-color: $color-self-collect !important;
        }
    }
    &.cancelled {
        button {
            background-color: $color-cancelled !important;
        }
    }
    &.failed {
        button {
            background-color: $color-failed !important;
        }
    }
}