.custom-dialog-component {
    .MuiDialog-paper {
        padding: 25px;
        border-radius: 8px;

        .MuiDialogTitle-root {
            padding-left: 0px;
            padding-right: 0px;
        }
        .MuiDialogContent-root {
            padding-left: 0px;
            padding-right: 0px;
        }
    }

    .ok-btn {
        &.default {
            background-color: $color-default;
            
            span {
                color: $color-secondary;
            }
        }
        &.danger {
            background-color: $color-danger;
        }
        &.warning {
            background-color: $color-warning;
        }
        &.success {
            background-color: $color-success;
        }
        &.info {
            background-color: $color-info;
        }
    }
}

.modal-subtitle {
    font-weight: 700;
    text-decoration: underline;
}