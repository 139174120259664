.pub-job-form {
    background-color: #66ccc9;
    display: flex;

    .cover {
        max-width: 480px;
        width: 100%;
        background: transparent url('../../img/background/truck.png') 0% 0% no-repeat padding-box;
        background-size: cover;
        position: relative;

        @include respond-below('lg') {
            display: none;
        }

        &__text {
            position: absolute;
            margin: 70px 45px;
            color: white;
            font-size: 40px;
            line-height: 51px;
            letter-spacing: 0;
            font-weight: bold;
            bottom: 0;
        }
    }

    .job-form {
        width: 100%;
        display: flex;
        flex-direction: column;

        .form-title {
            text-align: center;
            font-size: 34px;
            color: black;
            margin: 16px 0;
        }

        .form-description {
            font-size: 18px;
            text-align: center;
            margin: 16px 0;
            color: black;
        }

        .form-wrapper {
            background-color: #fff;
            max-width: 800px;
            margin: 30px auto;
            padding: 45px;
            flex: 1;
            justify-content: center;
            align-items: center;
            width: 100%;

            .logo {
                margin: 32px auto;
                display: flex;
                justify-content: center;

                img {
                    width: 154px;
                }
            }

            .custom-label {
                text-align: left;
            }
        }
        
        .cke {
            margin-bottom: 20px;
        }
    }

    .top-menu {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include respond-below('sm') {
            flex-direction: column;
        }

        .logo {
            margin: 32px 56px;

            img {
                width: 104px;
            }
        }

        .signup-acc {
            display: flex;
            align-items: inherit;
            margin: 0 32px;

            div {
                margin-right: 16px;
                font-size: 16px;
            }
        }
    }
}