.custom-input-component {
    margin-bottom: 20px;

    &.left {
        text-align: left !important;
        input {
            text-align: left !important;
        }
    }
    &.center {
        text-align: center !important;
        input {
            text-align: center !important;
        }
    }
    &.right {
        text-align: right !important;
        input {
            text-align: right !important;
        }
    }

    .custom-input {
        border: 1px solid #E0E1E4;
        border-radius: 4px;
        overflow: hidden;

        input, .MuiSelect-select, textarea {
            background-color: unset !important;
        }
        &.Mui-disabled {
            background-color: rgba($color-secondary, 0.3) !important;
        }
    }
    
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus { 
        -webkit-box-shadow: 0 0 0px 1000px #75787B inset;
    }

    .MuiInputBase-adornedEnd {
        background-color: #ffffff !important;
        span, i, svg {
            position: relative;
            color: #ADAFB3;
            cursor: pointer;
        }

        svg {
            margin-top: 2px;
            fill: #ADAFB3;

            rect, g, path {
                fill: #ADAFB3;
            }
        }
    }

    .MuiInputBase-adornedStart {
        > div {
            background-color: $color-default;
            position: absolute;
            left: 0;
            height: 100%;
            min-width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            span, i, svg {
                position: relative;
                color: #ADAFB3;
            }

            svg {
                width: 18px;
                fill: #ADAFB3;

                rect, g, path {
                    fill: #ADAFB3;
                }
            }
        }

        > span {
            background-color: $color-default;
            position: absolute;
            left: 0;
            right: unset;
            height: 100%;
            min-width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: default;

            span, i, svg {
                position: relative;
                color: #ADAFB3;
            }
    
            svg {
                width: 18px;
                fill: #ADAFB3;
    
                rect, g, path {
                    fill: #ADAFB3;
                }
            }
        }

        > p {
            background-color: white;
            position: absolute;
            left: 0;
            right: unset;
            height: 100%;
            min-width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0px;
            cursor: default;

            span, i, svg {
                position: relative;
                color: $color-default;
            }
    
            svg {
                width: 18px;
                fill: $color-default;
    
                rect, g, path {
                    fill: $color-default;
                }
            }
        }

        input, .MuiSelect-select, textarea {
            padding-left: 45px !important;
        }
    }

    .order-sequence {
        width: 50px !important;
        .MuiInputBase-input {
            // padding-right: unset !important;
        }
    }    
    
    .MuiInputBase-adornedEnd {
        > div {
            background-color: $color-default;
            position: absolute;
            right: 0;
            left: unset;
            height: 100%;
            min-width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            span, i, svg {
                position: relative;
                color: #ADAFB3;
            }
    
            svg {
                width: 18px;
                fill: #ADAFB3;
    
                rect, g, path {
                    fill: #ADAFB3;
                }
            }
        }

        > span {
            background-color: $color-default;
            font-size: 14px !important;
            position: absolute;
            right: 0;
            left: unset;
            height: 100%;
            min-width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: default;

            span, i, svg {
                position: relative;
                color: #ADAFB3;
            }
    
            svg {
                width: 18px;
                fill: #ADAFB3;
    
                rect, g, path {
                    fill: #ADAFB3;
                }
            }
        }

        > p {
            background-color: white;
            position: absolute;
            right: unset;
            left: 0;
            height: 100%;
            min-width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0px;
            cursor: default;

            span, i, svg {
                position: relative;
                color: $color-default;
            }
    
            svg {
                width: 18px;
                fill: $color-default;
    
                rect, g, path {
                    fill: $color-default;
                }
            }
        }

        input, .MuiSelect-select, textarea {
            padding-right: 50px !important;
        }
    }

    &.primary {
        .MuiInputBase-adornedStart {
            > div {
                background-color: $color-active !important;
    
                span, i, svg {
                    color: white;
                }
    
                svg {
                    fill: white;
    
                    rect, g, path {
                        fill:white;
                    }
                }
            }

            > span {
                background-color: $color-active !important;
    
                span, i, svg {
                    position: relative;
                    color:white;
                }
        
                svg {
                    width: 18px;
                    fill: white;
        
                    rect, g, path {
                        fill:white;
                    }
                }
            }
        }
    
        .MuiInputBase-adornedEnd {
            > div {
                background-color: $color-active !important;
    
                span, i, svg {
                    color: white;
                }
        
                svg {
                    fill:white;
        
                    rect, g, path {
                        fill:white;
                    }
                }
            }

            > span {
                background-color: $color-active !important;
    
                span, i, svg {
                    position: relative;
                    color:white;
                }
        
                svg {
                    width: 18px;
                    fill: white;
        
                    rect, g, path {
                        fill:white;
                    }
                }
            }
        }
    }

    &.secondary {
        .MuiInputBase-adornedStart {
            > div {
                background-color: $color-second !important;
                color:white;
    
                span, i, svg {
                    color: white;
                }
    
                svg {
                    fill: white;
    
                    rect, g, path {
                        fill:white;
                    }
                }
            }

            > span {
                background-color: $color-second !important;
                color:white;
    
                span, i, svg {
                    position: relative;
                    color:white;
                }
        
                svg {
                    width: 18px;
                    fill: white;
        
                    rect, g, path {
                        fill:white;
                    }
                }
            }
        }
    
        .MuiInputBase-adornedEnd {
            > div {
                background-color: $color-second !important;
                color:white;
    
                span, i, svg {
                    color: white;
                }
        
                svg {
                    fill:white;
        
                    rect, g, path {
                        fill:white;
                    }
                }
            }

            > span {
                background-color: $color-second !important;
                color:white;
    
                span, i, svg {
                    position: relative;
                    color:white;
                }
        
                svg {
                    width: 18px;
                    fill: white;
        
                    rect, g, path {
                        fill:white;
                    }
                }
            }
        }
    }

    .Mui-error {
        border: 1px solid rgba(207, 62, 62, 0.4) !important;
        
        input, .MuiSelect-select, textarea {
            border: 0px !important;
        }
    }

    input, .MuiSelect-select, textarea {
        min-height: 41px !important;
        color: $color-secondary;
        background-color: #ffffff !important;
        padding-left: 15px;
        font-family: $font-family-medium;
        font-size: 14px;
        line-height: 41px;
        padding-top: 0px;
        padding-bottom: 0px;
        z-index: 1;

        &::placeholder {
            font-family: $font-family-regular;
            color: rgba(#75787B, 0.40);
            opacity: 1;
        }

        .placeholder {
            font-size: 14px;
            font-family: $font-family-regular;
            color: rgba(#75787B, 0.40);
        }

        &[aria-invalid="true"] {
            border: 1px solid rgba($color-danger, 0.40);
        }
    }

    textarea {
        line-height: 20px;
        padding-right: 15px;
    }
    .MuiInput-underline:before, .MuiInput-underline:after {
        content: none !important;
    }

    .MuiInputLabel-asterisk {
        display: none;
    }

    &.no-margin {
        margin: 0px;
    }

    input[type="date"]::-webkit-calendar-picker-indicator,
    input[type="time"]::-webkit-calendar-picker-indicator {
        // display: none;
        // -webkit-appearance: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -30px;
        right: 0;
        width: auto;
        height: auto;
        outline: 0;
        color: transparent;
        background: transparent;
        cursor: pointer;
    }

    &.custom-password {
        input {
            z-index: 0;
        }

        .MuiInputBase-adornedEnd,
        .MuiInputBase-adornedStart {
            > div {
                background-color: transparent !important;

                i {
                    color: #75787B !important;
                }
            }
        }
    }
    
    &.custom-search {
        input {
            z-index: 0;
        }

        .MuiInputBase-adornedEnd,
        .MuiInputBase-adornedStart {
            > div {
                background-color: transparent !important;

                i {
                    color: #75787B !important;
                }
            }
        }
    }
}
.searchJob {
    .MuiInputBase-adornedEnd{
        height: 55px;
    }

}