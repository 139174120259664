.custom-alert-component {
    .MuiAlert-standardSuccess {
        background-color: rgba(#64CCC9, 0.10);

        svg {
            margin-top: 2px;
        }

        * {
            color: #64CCC9;
        }
    }
}