.custom-card-component {
    background-color: white;
    border: 1px solid #EAEDF2;
    border-radius: 10px;
    padding: 20px 25px;
    margin: 20px 25px;

    @include respond-below('lg') {
        margin: 10px 10px;
        padding: 10px 10px;
    }

    &.no-padding {
        padding: 0px !important;
    }

    &.no-margin {
        margin: 0px !important;
    }

    &.no-radius {
        border-radius: 0px !important;
    }

    &.no-border {
        border: 0px !important;
    }
}