.custom-select-menu {
    z-index: 9999 !important;
}

.custom-select-component {
    margin-bottom: 20px;

    &.no-margin {
        margin-bottom: 0px !important;
    }

    .MuiInput-root {
        width: 100%;

        &:before, &:after {
            content: none;
        }

        
        .MuiSelect-nativeInput {
            // opacity: 1 !important;
            width: 100%;
            height: 100%;
            bottom: unset !important;
            z-index: 0;
            border: 0px;
            background-color: transparent !important;
            // background-color: #ffffff !important;
            padding-left: 15px;

            &::placeholder {
                font-family: $font-family-regular;
                color: rgba(#75787B, 0.40);
                opacity: 1;
            }
        }

        .MuiSelect-select {
            min-height: 41px !important;
            color: $color-secondary;
            background-color: transparent !important;
            padding-left: 15px;
            font-family: $font-family-medium;
            font-size: 14px;
            line-height: 41px;
            padding-top: 0px;
            padding-bottom: 0px;
            border: 1px solid #E0E1E4;
            border-radius: 4px;

            .custom-option {
                background-color: white;
                position: relative;
                z-index: 1;
            }

            &::placeholder {
                font-family: $font-family-regular;
                color: rgba(#75787B, 0.40);
                opacity: 1;
            }

            .placeholder {
                font-size: 14px;
                font-family: $font-family-regular;
                color: rgba(#75787B, 0.40);
            }

            &[aria-invalid="true"] {
                border: 1px solid rgba($color-danger, 0.40);
            }
        }

        .MuiSelect-icon {
            display: none !important;
        }
    }

    .MuiInputLabel-asterisk {
        display: none;
    }

    &.head-select {
        .MuiSelect-select {
            min-height: 40px !important;
            line-height: 40px !important;
        }
    }

    &.white {
        .MuiSelect-select {
            background-color: white !important;
            color: transparent !important;
        }
    }

    .Mui-error {
        .MuiSelect-select {
            border: 1px solid rgba(207, 62, 62, 0.4) !important;
        }
    }
}