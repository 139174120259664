.custom-tooltip-component {
    font-size: 25px !important;
    position: absolute;
    top: -1px;
    margin-left: 10px;
    color: rgba($color-secondary, 0.60) !important;
    cursor: help;

    &.txt-dark {
        color: $color-secondary !important;
    }

    &-span {
        font-size: 12px !important;
        color: rgba($color-secondary, 0.60) !important;
    }
}

div {
    &[role="tooltip"] {
        pointer-events: unset !important;
        z-index: 99999;
    }
}